<template>
  <section>
    <div class="div-1280 top-breadcrumb">
      <v-layout wrap>
        <v-flex md6 class="breadcrumb-left hidden-sm-and-down">睡眠环境改善案例</v-flex>
        <v-flex md6 class="hidden-sm-and-down"><v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs></v-flex>
      </v-layout>
    </div>
    <div class="div-1280 ctk-container" v-if="detail">
      <h3 class="article-title wowd fadeInUp">{{detail.title}}</h3>
      <div class="article-action wowd fadeInUp">
        <span class="article-date">{{detail.create_time}}</span>
      </div>
      <div v-html="detail.content" class="article-content wowd fadeInUp" data-wow-delay="0.2s"></div>
    </div>
  </section>
</template>
<script>
export default {
  data: () => ({
    pageId: 4,
    breadcrumbs: [
      { text: '首页', disabled: false, href: '/' },
      { text: '解决方案', disabled: false, href: 'javascript:void()' },
      { text: '睡眠环境改善案例', disabled: false, href: '/cases' }
    ],
    detail: null
  }),
  created () {
    this.getCase(this.$route.params)
    document.querySelector('#menu_scheme').classList.add('v-btn--active')
  },
  watch: {
    '$store.getters.companyInfo': {
      handler: function (val, oldVal) {
      },
      deep: true
    }
  },
  computed: {
  },
  methods: {
    getCase (param) {
      this.https.get('case', param).then(response => {
        if (response.code === 0) {
          this.detail = response.data
          document.title = this.detail.title + '-好睡眠'
          this.breadcrumbs.push({ text: this.detail.title.substring(0, 6) + '...', disabled: true })
        }
      }).then(_ => {
        this.$wowd.init()
      })
    }
  }
}
</script>
<style lang="css">
  @import '~@/assets/css/article.css';
</style>
